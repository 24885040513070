import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React, {useEffect } from 'react';
import HomePage from "./Pages/Home";
import ContactPage from "./Pages/Contact";
import OrderPage from "./Pages/Order";
import BookingPage from "./Pages/Booking";
import MenuPage from "./Pages/Menu";
import Navbar from "./Pages/Components/NavBar/NavBar";
import ReactGA from 'react-ga';
  const TRACKING_ID = "G-GYRK7T4Q6K"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

const App = () => {
  ReactGA.initialize(TRACKING_ID);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/about" element={<ContactPage />} />
        <Route path="/booking" element={<BookingPage />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
