import {
  PageContent,
  Body,
  HeadingGrid,
  DeliveryImageGrid,
  FullOrderPage,
  DeliveryGridImage,
} from "./Components/GlobalElements";
import { Box, Divider, Typography } from "@mui/material";
import StickyFooter from "./Components/StickyFooter";
import { deliveryItems } from "./Data/deliveryimages";

const OrderPage = () => {
  return (
    <div>
      <FullOrderPage>
        <PageContent>
          <Body>
            <HeadingGrid>
              <Typography
                variant="h4"
                align="center"
                sx={{ color: "yellow", textShadow: "3px 3px 2px black" }}
              >
                Order Delivery Here
              </Typography>
              <Divider sx={{ bgcolor: "white" }} />
              <Typography variant="h5" align="center">
                Currently servicing the Caloundra area via UberEats and DoorDash
              </Typography>
              <Divider sx={{ bgcolor: "white" }} />
            </HeadingGrid>
            <Box mt={3}>
              <DeliveryImageGrid>
                {deliveryItems.map((item) => (
                  <div>
                    <a href={item.link}>
                      <DeliveryGridImage
                        src={`${item.img}`}
                        srcSet={`${item.img}`}
                        alt={item.title}
                        loading="eager"
                      />
                    </a>
                  </div>
                ))}
              </DeliveryImageGrid>
            </Box>
          </Body>
        </PageContent>
      </FullOrderPage>
      <StickyFooter />
    </div>
  );
};

export default OrderPage;
