import c from "../../Images/3.png";
import e from "../../Images/5.png";
import i from "../../Images/9.png";
import l from "../../Images/12.png";

export const qualityImageList = [
  {
    img: l,
    title: "Beef and Broccoli",
  },
  {
    img: i,
    title: "Stiry Fry Vegetables",
  },
  {
    img: e,
    title: "Spring Roll",
  },
  {
    img: c,
    title: "Garlic King Prawn",
  },
]