import Left from "./images/Left.png";
import Right from "./images/Right.png";
import Middle from "./images/Middle.png";
import Left_2 from "./images/Left_2.png";
import Middle_2 from "./images/Middle_2.png";


export const takeawayImages = [
  {
    img: Left,
    title: "Entree and Chef's Suggestions",
  },
  {
    img: Middle,
    title: "Soup, Beef and Chicken",
  },
  {
    img: Right,
    title: "Omelette, Sweet & Sour, King Prawns, BBQ Pork and Duck",
  },

  {
    img: Middle_2,
    title: "Scallops & Fish, Hokkien Noodles, Thin Egg Noodles and Rice",
  },
  {
    img: Left_2,
    title: "Special Family Packs",
  },
]