import {
  FullOrderPage,
  PageContent,
  Body,
  HeadingGrid,
  TextGrid,
  CleanLink,
} from "./Components/GlobalElements";
import { Box,  Divider, Typography } from "@mui/material";
import StickyFooter from "./Components/StickyFooter";

const BookingPage = () => {
  return (
    <div>
      <FullOrderPage>
        <PageContent>
          <Body>
            <HeadingGrid>
              <Typography
                variant="h4"
                align="center"
                sx={{ color: "yellow", textShadow: "3px 3px 2px black" }}
                >
                Book Now
              </Typography>
              <Divider sx={{ bgcolor: "white" }} />
              <Typography variant="h5" align="center">
                We currently take bookings via phone call or email.
              </Typography>
            </HeadingGrid>
            <Box mt={3}>
              <TextGrid>
                <Typography variant="h5" align="center">
                  We are open for dinner every day from 4:45pm till late and
                  take bookings anytime. <br />
                </Typography>
                <Typography variant="h5" align="center">
                  We will confirm your booking over the phone
                </Typography>
                <Typography variant="h6" align="center">
                  <b>Phone: </b> <br />
                  <CleanLink href="tel:07 5491 2788">
                    {" "}
                    07 5491 2788
                  </CleanLink>{" "}
                  or <CleanLink href="tel:0423 811 291">0423 811 291</CleanLink>
                </Typography>
                <Typography variant="h6" align="center">
                  <b>Email:</b> <br />
                  <CleanLink href="mailto:emilyzheng@live.com">
                   emilyzheng@live.com
                  </CleanLink>
                </Typography>
              </TextGrid>
            </Box>
          </Body>
        </PageContent>
      </FullOrderPage>
      <StickyFooter />
    </div>
  );
};

export default BookingPage;
