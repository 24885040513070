import UberEats from "./images/UberEats.jpg"
import DoorDash from "./images/DoorDash.png"


export const deliveryItems = [ 
    {
        link: "https://www.ubereats.com/au/store/caloundra-golden-dragon/LYUKVqsvRzWf_OOyFVtLyw",
        name: "Uber Eats",
        img: UberEats
    },
    {
        link: "https://www.doordash.com/en-AU/store/golden-dragon-chinese-restaurant-caloundra-2270870",
        name: "Door Dash",
        img: DoorDash
    },
]