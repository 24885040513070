import styled from "styled-components";

export const FullPage = styled.div`
  margin: 0;
  width: 100%;
  height: 100vh;
  background-color: #AE7C82;
  color: black;
  @media screen and (max-width: 1200px) {
    height: 100%;
  }
`;

export const FullOrderPage = styled.div`
  margin: 0;
  width: 100%;
  height: 100vh;
  background-color: #AE7C82;
  color: black;
`;

export const Heading = styled.div`
  color: #fff52e;
`;

export const Body = styled.div`
  margin: auto;
`;

export const PageContent = styled.div`
  display: grid;
  padding-top: 5rem;
  grid-template-columns: auto;
  grid-gap: 1.5rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

export const TableGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 1.5rem;
  padding: 1.5rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

export const TextGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1.5rem;
  padding: 1.5rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: auto;
  }
  @media screen and (max-width: 100px) {
   width: 80%
  }
`;

export const ButtonGrid = styled.div`
  display: grid;
  padding: 1.5rem;
  grid-template-columns: auto auto;
  grid-gap: 1.5rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: auto;
  }
`;


export const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 1.5rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: auto auto;
  }
`;
export const MapImageGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MapGridImage = styled.img`
max-width: 400px;
width: 100%;
height: 100%;
  object-fit: cover;

  @media screen and (max-width: 2px) {
    width: 60%;
  }
`;

export const MenuImageGrid = styled.div`
  display: grid;
  padding: 0.5em;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 1.5rem;
  @media screen and (max-width: 1200px) {
    grid-template-columns:  auto auto auto;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: auto auto;
  }
`;

export const DeliveryImageGrid = styled.div`
  display: grid;
  padding: 0.5em;
  grid-template-columns: auto auto;
  grid-gap: 1.5rem;
`;

export const DeliveryGridImage = styled.img`
max-width: 400px;
width: 100%;
height: 100%;
  object-fit: cover;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;


export const GridImage = styled.img`
max-width: 275px;
height: 90%;
  object-fit: cover;
  @media screen and (max-width: 1200px) {
    max-width: 200px;
    height: 90%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

export const HeadingGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1.5em;
  @media screen and (max-width: 768px) {
    grid-template-columns: auto;
  }
`;
export const ListGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0.5em;
  @media screen and (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

export const CleanLink = styled.a`
font-style: italic;
text-decoration: none;
`;
