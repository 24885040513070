import * as React from "react";
import Typography from "@mui/material/Typography";
import {Container, Box} from "@mui/material";
import Link from "@mui/material/Link";

function Copyright() {
  return (
    <Typography variant="body2">
      {`Website design by `}
      <Link href="https://www.mackenzie-design.co">Mackenzie Design Co</Link>
    </Typography>
  );
}

export default function StickyFooter() {
  return (
    <Box
      component="footer"
      sx={{
        py: "1em",
        mt: "auto",
        color: "grey",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="sm">
        <Typography variant="body1">
          <Copyright />
        </Typography>
      </Container>
    </Box>
  );
}
