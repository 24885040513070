import {
  FullPage,
  PageContent,
  Body,
  HeadingGrid,
  TextGrid,
  MapImageGrid,
  MapGridImage,
} from "./Components/GlobalElements";
import Map from "../Images/map.png"

import { Box, Divider, Typography } from "@mui/material";
import StickyFooter from "./Components/StickyFooter";
const ContactPage = () => {
  return (
    <div>
      <FullPage>
        <PageContent>
          <Body>
            <HeadingGrid>
              <Typography
                variant="h4"
                align="center"
                sx={{ color: "yellow", textShadow: "3px 3px 2px black" }}
              >
                About Us
              </Typography>
              <Divider sx={{ bgcolor: "white" }} />
              <Typography variant="h5" align="center">
                Give us a call or email us anytime
              </Typography>
              <Divider sx={{ bgcolor: "white" }} />
            </HeadingGrid>
            <Box mt={3}>
              <TextGrid>
                <Typography variant="h6" align="center">
                  <b>Phone:</b> 07 5491 2788
                </Typography>
                <Typography variant="h6" align="center">
                  <b>Email:</b> emilyzheng@live.com
                </Typography>
                <Typography variant="h6" align="center">
                  <b>Address:</b> 1st Floor, Henzells Plaza 45 Minchinton Street Caloundra
                </Typography>
                <MapImageGrid>
                  <MapGridImage
                    src={Map}
                    srcSet={Map}
                    alt={'address'}
                    loading="eager"
                  />
                </MapImageGrid>
                <Typography variant="h6" align="center">
                  Open for dinner every day from 4:45pm till late
                </Typography>
              </TextGrid>

            </Box>
          </Body>
        </PageContent>
      </FullPage>
      <StickyFooter />
    </div>
  );
};

export default ContactPage;
